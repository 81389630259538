<template>
  <BaseModal
    v-bind="$props"
    :title="$filters.empty(title, $t('Modal.Signup.Title'))"
    @backdropClicked="hide"
    :withBottomDivider="true"
  >
    <form v-if="!success" @submit.prevent="submit" id="signupForm" class="pb-5 flex flex-col gap-2">
      <div>
        <label for="" class="font-medium text-100 text-gray-500 block mb-1">
          {{ $t('Modal.Signup.Name') }}
        </label>
        <InputVerify
          v-model="name"
          :placeholder="$t('Modal.Signup.NamePlaceholder')"
          type="text"
          required
          :messageIconVisible="error.length > 0"
          messageVariant="error"
        ></InputVerify>
      </div>
      <div>
        <label for="" class="font-medium text-100 text-gray-500 block mb-1">
          {{ $t('Modal.Signup.Email') }}
        </label>
        <InputVerify
          v-model="email"
          :placeholder="$t('Modal.Signup.EmailPlaceholder')"
          type="email"
          required
          :messageIconVisible="error.length > 0"
          messageVariant="error"
        ></InputVerify>
      </div>
      <div v-if="showNotes" class="-mt-4">
        <ReadMoreSpan
          v-if="event.registration_notes_title"
          class="block mb-1 text-100 text-gray-500 leading-relaxed w-full font-medium max-w-lg"
          :text="event.registration_notes_title"
          :maxLength="140"
          :markdown="true"
        />
        <InputMessage
          class="w-full"
          :placeholder="$t('Modal.Signup.NotesPlaceholder')"
          :multiline="true"
          :hideSendButton="true"
          v-model="notes"
        />
      </div>
    </form>
    <template v-if="!success" v-slot:bottom>
      <div class="flex flex-col sm:flex-row w-full sm:w-auto justify-end p-6 gap-2">
        <ButtonElement @click.native="hide" variant="secondary-small">{{ $t('Modal.Generic.Cancel') }}</ButtonElement>
        <ButtonElement variant="primary-small" type="submit" form="signupForm">{{
          $t('Modal.Signup.Signup')
        }}</ButtonElement>
      </div>
    </template>
    <div v-if="success">
      <div class="flex flex-col items-center pb-12">
        <EventsSuccessIllustration class="mb-8" />
        <h3 class="font-bold text-gray-900 mb-1">Aanmelden gelukt!</h3>
        <p class="text-100 text-gray-500 mb-6">We nemen zo snel mogelijk contact met je op.</p>
        <div class="flex flex-col gap-2">
          <EventsAddToCalendarButton
            v-if="event.calendar_visible && $permissions.has('event-registration-create')"
            :event="event"
          />
          <!-- <ButtonElement @click.native="hide" variant="secondary-small">{{ $t('Modal.Generic.Cancel') }}</ButtonElement> -->
          <ButtonElement variant="primary-small" @click.native="hide">{{ $t('Modal.Generic.Close') }}</ButtonElement>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal';
import ButtonElement from '@/components/ButtonElement';
import InputVerify from '@/components/InputVerify';
import InputMessage from '@/components/InputMessage';
import ReadMoreSpan from '@/components/ReadMoreSpan';

import EventsAddToCalendarButton from '@/components/events/EventsAddToCalendarButton.vue';

import EventsSuccessIllustration from '@/components/events/EventsSuccessIllustration.vue';
import { GraphQLMutation } from '@/graphql';

export default {
  name: 'SignupModal',
  extends: BaseModal,
  inheritAttrs: false,
  components: {
    BaseModal,
    ButtonElement,
    InputVerify,
    InputMessage,
    ReadMoreSpan,
    EventsSuccessIllustration,
    EventsAddToCalendarButton
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: '',
      email: '',
      notes: '',
      error: '',
      success: false
    };
  },
  computed: {
    showNotes() {
      return this.event?.registration_notes_title?.trim()?.length;
    }
  },
  methods: {
    async submit() {
      try {
        await this.$apollo.mutate({
          mutation: GraphQLMutation.EventRegistrationCreate,
          variables: {
            name: this.$data.name,
            email: this.$data.email,
            registration_notes_answer: this.notes || '',
            event: this.event.id
          }
        });

        this.$data.success = true;
      } catch (e) {
        const errorId = e.graphQLErrors[0]?.message || 'unknown';
        if (errorId === 'event.notfound') {
          this.$modal.show('ErrorModal', {
            title: this.$t('Events.Error.RegistrationEventNotFound.Title'),
            error: this.$t('Events.Error.RegistrationEventNotFound.Body')
          });
        } else {
          this.$modal.show('ErrorModal', {
            title: this.$t('Events.Error.RegistrationCreateFailed.Title'),
            error: this.$t('Events.Error.RegistrationCreateFailed.Body')
          });
        }
      }
    }
  }
};
</script>
