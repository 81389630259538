<template>
  <div class="mt-5">
    <header class="flex justify-between mb-4 gap-5">
      <h3 class="font-bold text-gray-900 lg:text-500 flex-grow">Gezinshuizen door het land</h3>
      <div class="relative w-full mb-3" style="max-width: 378px">
        <select
          @input="filterHomes($event.target.value)"
          class="rounded w-full h-12 border border-gray-200 shadow-sm pl-4 pr-10 text-100 appearance-none cursor-pointer focus:outline-none"
        >
          <option value="">Alle regio's</option>
          <option value="Regio 1">Regio 1</option>
          <option value="Regio 2">Regio 2</option>
          <option value="Regio 3">Regio 3</option>
          <option value="Regio 4">Regio 4</option>
          <option value="Regio 5">Regio 5</option>
          <option value="Regio 6">Regio 6</option>
        </select>
        <IconChevronRight class="absolute right-4 top-5 rotate-90 transform pointer-events-none" />
      </div>
    </header>

    <div class="bg-white rounded p-4 shadow-sm">
      <div class="relative">
        <div v-if="loading" class="flex items-center justify-center w-full pt-4">
          <img class="w-8 h-8 mb-4 relative" src="@/assets/loading-dark.gif" alt="Loading" />
        </div>
        <GmapMap
          v-if="!loading"
          ref="mapRef"
          :center="center"
          :zoom="zoom"
          :options="{ mapTypeControl: false }"
          map-type-id="terrain"
          class="map"
        >
          <gmap-custom-marker
            :key="index"
            v-for="(m, index) in markers"
            :marker="{ lat: m.lat, lng: m.lng }"
            @click.native="markerClick(m.id)"
          >
            <DashboardMapMarker
              class="text-red-500 cursor-pointer transition-all duration-300"
              :class="{ 'text-darkblue': activeHome?.id === m.id }"
            />
            <i
              v-if="activeHome?.id === m.id"
              class="w-2 h-2 absolute left-0 right-0 mx-auto bg-white rounded-full"
              style="top: 22px;"
            ></i>
          </gmap-custom-marker>
        </GmapMap>
        <div
          v-if="activeHome"
          class="fixed lg:absolute bottom-0 lg:bottom-auto left-0 lg:top-10 lg:left-10 bg-white rounded p-8 w-full lg:max-w-sm"
        >
          <button
            class="absolute top-0 right-0 lg:-mt-2 lg:-mr-2 w-8 h-8 lg:rounded-full bg-red-500 text-white flex items-center justify-center focus:outline-none"
            @click="activeHome = null"
          >
            <IconX />
          </button>
          <div class="flex justify-between">
            <div v-if="activeHome?.logo?.file[0].url" class="relative w-30 h-20 border rounded mb-4">
              <img
                :src="activeHome.logo.file[0].url"
                class="absolute top-0 left-0 w-full h-full object-contain"
                alt=""
              />
            </div>
            <div class="flex ml-auto mb-5">
              <button
                class="w-8 h-8 flex items-center justify-center focus:outline-none"
                :class="{ 'opacity-20 pointer-events-none': firstHomeActive }"
                @click="prev(activeHome.id)"
              >
                <IconChevronLeft />
              </button>
              <button
                class="w-8 h-8 flex items-center justify-center focus:outline-none"
                :class="{ 'opacity-20 pointer-events-none': lastHomeActive }"
                @click="next(activeHome.id)"
              >
                <IconChevronRight />
              </button>
            </div>
          </div>
          <h4 class="font-semibold text-200 text-darkblue">{{ activeHome.name }}</h4>
          <p class="text-lightblue-text">{{ activeHome.address }}</p>
          <p v-if="activeHome?.geo_location?.additional" class="text-lightblue-text">
            {{ activeHome?.geo_location?.additional }}
          </p>
          <div v-if="contactPersons && contactPersons.length > 0" class="mt-5">
            <h5 class="font-semibold text-darkblue mb-1">Contact</h5>
            <div class="flex gap-2 items-center mb-1">
              <IconTelephone class="text-lightblue-text" />
              <span class="text-lightblue-link">{{ contactPersons[0].phone }}</span>
            </div>
            <div class="flex gap-2 items-center mb-1">
              <IconEmail class="text-lightblue-text" />
              <span class="text-lightblue-link">{{ contactPersons[0].email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { GraphQLQuery } from '@/graphql';
import DashboardMapMarker from './DashboardMapMarker';

export default {
  components: {
    DashboardMapMarker,
    'gmap-custom-marker': GmapCustomMarker
  },

  data() {
    return {
      loading: true,
      homes: [],
      activeHome: null,
      filter: null,
      zoom: 8,
      center: { lat: 52.2129919, lng: 5.2793703 }
    };
  },

  async mounted() {
    this.fetchHomes();
  },

  watch: {
    filter: {
      immediate: false,
      handler() {
        this.zoom = 8;
        this.activeHome = null;
        this.$refs.mapRef.$mapPromise.then(map => {
          map.panTo({ lat: 52.2129919, lng: 5.2793703 });
        });
      }
    }
  },

  computed: {
    google: getGoogleMapsAPI,

    contactPersons() {
      if (this.activeHome) {
        return this.activeHome.users.filter(user => user.role.name === 'Parent');
      }
      return null;
    },

    filteredHomes() {
      if (this.homes.length && this.filter) {
        const homesWithLocation = this.homes.filter(home => home.geo_location?.area?.region === this.filter);
        return homesWithLocation;
      }
      return this.homes;
    },

    firstHomeActive() {
      if (this.activeHome) {
        const currentIndex = this.filteredHomes.findIndex(home => home.id === this.activeHome.id);
        return currentIndex === 0;
      }
      return null;
    },

    lastHomeActive() {
      if (this.activeHome) {
        const homeCount = this.filteredHomes.length;
        const currentIndex = this.filteredHomes.findIndex(home => home.id === this.activeHome.id);
        return currentIndex === homeCount - 1;
      }
      return null;
    },

    markers() {
      if (this.filteredHomes.length) {
        return this.filteredHomes.map(item => {
          return {
            id: item.id,
            lat: item.geo_location.location.lat,
            lng: item.geo_location.location.lng
          };
        });
      }

      return [];
    }
  },

  methods: {
    async fetchHomes() {
      this.loading = true;

      try {
        const { data } = await this.$apollo.query({
          query: GraphQLQuery.HomesMap,
          variables: {
            query: {
              _and: [
                { address_null: false },
                { address_ne: '' },
                { geo_location_null: false },
                { visible_on_map_portal_eq: true },
                { logo_null: false }
              ]
            },
            limit: 9999,
            start: 0
          }
        });

        this.homes = data?.homesMap;
      } catch (e) {
        // Ignore error
      }

      this.loading = false;
    },

    filterHomes(event) {
      if (event === '') {
        this.filter = null;
      }
      this.filter = event;
    },

    markerClick(id) {
      this.activeHome = this.filteredHomes.find(home => Number(home.id) === Number(id));

      this.zoom = 11;

      this.panToLocation();
    },

    panToLocation() {
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo({ lat: this.activeHome.geo_location.location.lat, lng: this.activeHome.geo_location.location.lng });
      });
    },

    prev(id) {
      const currentIndex = this.filteredHomes.findIndex(home => Number(home.id) === Number(id));
      this.activeHome = this.filteredHomes[currentIndex - 1];

      this.panToLocation();
    },

    next(id) {
      const currentIndex = this.filteredHomes.findIndex(home => Number(home.id) === Number(id));
      this.activeHome = this.filteredHomes[currentIndex + 1];
      this.panToLocation();
    }
  }
};
</script>

<style>
/* purgecss start ignore */
.vue-map-container iframe + div {
  border: 0 !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gm-style-cc {
  display: none;
}

.map {
  width: 100%;
  height: 800px;
  max-height: 80vh;
}
@media only screen and (max-width: 600px) {
  .map {
    height: 400px;
  }
}
/* purgecss end ignore */
</style>
